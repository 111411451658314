<script setup lang="ts">
const expensivePropertyQuery = groq`*[_type == "properties" && database == "1" && price.amount >= 1000000]{
  title,
  slug,
  price,
  uploaded,
  bedrooms,
  bathrooms,
  receptions,
  database,
  web_status,
  description,
  landarea,
  files,
} | order(uploaded desc)[0...8]`

const sanity = useSanity()
const { data: properties, error } = await useAsyncData(expensivePropertyQuery, () => sanity.fetch<Sanity.Default.Schema.Properties[]>(expensivePropertyQuery))
// // console.log(properties.value)

if (error.value) {
  // console.log('Error fetching properties')
  console.error(error)
}
</script>

<template>
  <UiFader
    v-if="properties"
    class=""
  >
    <FeaturesCarousel>
      <template #button>
        <UiBtn
          variant="link"
          color="forest"
          size="lg"
          to="/browse"
          label="Latest Properties"
          class="mt-1 mr-4"
        />
      </template>
      <div
        v-for="property in properties"
        :key="property?.slug.current"
        class="snap-start lg:last:pr-4 magic-padding shrink-0 w-5/6 md:w-auto"
      >
        <PropertyCard
          :property="property"
          class="w-full md:w-[400px] lg:w-[500px] h-full"
        />
      </div>
    </FeaturesCarousel>
  </UiFader>
</template>
